import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState, useContext } from "react";
import Title from "../components/title";
import { Link, useHistory, useParams } from "react-router-dom";
import { cropsApi } from "../services/api/crops";
import CropForm, {
  CropFormValues,
  convertToUnitSystem,
  otherUnitSystem,
} from "../components/crop-form";
import { Crop } from "../models/crop";
import { Box, useTheme } from "@mui/material";
import LoadingBackdrop from "../components/loading-backdrop";
import { ErrorContext, UserContext } from "../components/app-routes";
import { seedsApi } from "../services/api/seeds";
import { Seed } from "../models/seed";
import { DateTime } from "luxon";
import mixpanel from "mixpanel-browser";
import { goBackOrTo } from "../utils/history";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ConfirmDialog from "../components/confirm-dialog";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  actions: {
    "& > *": {
      marginRight: theme.spacing(1),
    },
  },
}));

export default function CropPage() {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [busy, setBusy] = useState(false);
  const [crop, setCrop] = useState<Crop | null>(null);
  const [saved, setSaved] = useState(false);
  const { handleError } = useContext(ErrorContext);
  const [seeds, setSeeds] = useState<Seed[] | undefined>(undefined);
  const theme = useTheme();
  const [
    openChangeCropConfirmDialog,
    setOpenChangeCropConfirmDialog,
  ] = useState(false);
  const [initialValues, setInitialValues] = useState<
    CropFormValues | undefined
  >(undefined);
  const [submittedValues, setSubmittedValues] = useState<
    CropFormValues | undefined
  >(undefined);
  const context = useContext(UserContext);
  const grower = context.grower;

  if (!grower) {
    throw new Error("Invalid context");
  }

  useEffect(() => {
    async function getCrop() {
      setBusy(true);
      try {
        const [crop, seeds] = await Promise.all([
          cropsApi.getSingle(parseInt(id)),
          seedsApi.getAll(),
        ]);
        setCrop(crop);
        setSeeds(seeds);
        setInitialValues({
          name: crop.name,
          seedId: crop.seedId,
          seedingRate: crop.seedingRate.toString(),
          seedingRateOther: convertToUnitSystem(
            otherUnitSystem(grower!.UnitSystem),
            crop.seedingRate
          ).toFixed(2),
          overnightSoak: crop.overnightSoak,
          soakTimeMinutes: crop.soakTimeMinutes.toString(),
          germinationDays: crop.germinationDays.toString(),
          blackoutDays: crop.blackoutDays.toString(),
          daysToMaturity: crop.daysToMaturity.toString(),
          cutYield: crop.cutYield?.toString(),
          notes: crop.notes,
          archived: !!crop.archivedAt,
          bufferPercentage: crop.bufferPercentage.toString(),
        });
      } catch (error) {
        handleError(error);
      } finally {
        setBusy(false);
      }
    }
    getCrop();
  }, [id]);

  const updateCrop = async (values: CropFormValues) => {
    if (!crop || !initialValues) {
      throw new Error("Invalid state");
    }

    setBusy(true);

    try {
      await cropsApi.update({
        id: crop.id,
        name: values.name,
        seedId: values.seedId,
        seedingRate: parseFloat(values.seedingRate),
        overnightSoak: values.overnightSoak,
        soakTimeMinutes: parseInt(values.soakTimeMinutes),
        germinationDays: parseInt(values.germinationDays),
        blackoutDays: parseInt(values.blackoutDays),
        daysToMaturity: parseInt(values.daysToMaturity),
        cutYield: values.cutYield ? parseFloat(values.cutYield) : undefined,
        notes: values.notes,
        archivedAt:
          !!crop.archivedAt === values.archived
            ? crop.archivedAt
            : values.archived
            ? DateTime.now().toISO()
            : null,
        bufferPercentage: parseFloat(values.bufferPercentage),
      });

      setSaved(true);

      mixpanel.track("Update Crop", {
        bufferPercentage: parseFloat(values.bufferPercentage),
      });

      goBackOrTo(history, "/home/crops");
    } catch (error) {
      handleError(error);
    } finally {
      setBusy(false);
    }
  };

  const handleSubmit = async (values: CropFormValues) => {
    if (!crop || !initialValues) {
      throw new Error("Invalid state");
    }

    if (
      initialValues.blackoutDays !== values.blackoutDays ||
      initialValues.daysToMaturity !== values.daysToMaturity ||
      initialValues.germinationDays !== values.germinationDays ||
      initialValues.overnightSoak !== values.overnightSoak
    ) {
      setSubmittedValues(values);
      setOpenChangeCropConfirmDialog(true);
      return;
    }

    updateCrop(values);
  };

  const onClickCancel = () => {
    goBackOrTo(history, "/home/crops");
  };

  const onClickCloseChangeCropConfirmDialog = (
    confirm: boolean | undefined
  ) => {
    setOpenChangeCropConfirmDialog(false);

    if (!confirm) {
      return;
    }

    if (!submittedValues) {
      throw new Error("Invalid state");
    }

    updateCrop(submittedValues);
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Title>Crop</Title>
          {crop && seeds && initialValues && (
            <>
              <div>
                <Button
                  sx={{ marginBottom: theme.spacing(2) }}
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={{
                    pathname: "/home/crops/add",
                    state: {
                      crop: {
                        ...crop,
                        name: `${crop.name} (COPY)`,
                      },
                    },
                  }}
                  onClick={() => mixpanel.track("Duplicate Crop")}
                  disabled={busy}
                  startIcon={<ContentCopyIcon />}
                >
                  Duplicate
                </Button>
              </div>

              <CropForm
                initialValues={initialValues}
                seeds={seeds}
                onSubmit={handleSubmit}
                saved={saved}
              >
                <Box mt={2} className={classes.actions}>
                  <Button
                    variant="outlined"
                    disabled={busy}
                    onClick={onClickCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={busy}
                  >
                    Save
                  </Button>
                </Box>
              </CropForm>
            </>
          )}
        </Paper>
      </Grid>
      <LoadingBackdrop open={busy} />
      <ConfirmDialog
        title="Warning"
        description={
          <div>
            Changing a crop's values could affect Tasks that have already been
            completed, including shifting Tasks from one day to another.
            <br />
            <br />
            Unsure what to do?{" "}
            <a
              href="https://seedleaf.freshdesk.com/support/solutions/articles/72000625968"
              target="_blank"
            >
              Read more here
            </a>
            .
            <br />
            <br />
            Would you like to proceed?
          </div>
        }
        onClose={onClickCloseChangeCropConfirmDialog}
        open={openChangeCropConfirmDialog}
      />
    </Container>
  );
}
